import * as React from 'react'
import { Link } from 'gatsby'

import StyledPage from '../components/StyledPage'
import Container from '../components/Container'
import IndexLayout from '../layouts'

const NotFoundPage = () => (
  <IndexLayout>
    <StyledPage>
      <Container>
        <h1>404: Page not found.</h1>
        <p>
          You've hit the void. <Link to="/">Go back.</Link>
        </p>
      </Container>
    </StyledPage>
  </IndexLayout>
)

export default NotFoundPage
